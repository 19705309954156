import React, { useState, useEffect } from 'react';

import { Box, Button, Container, Dialog, DialogActions,  DialogContent,  Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { DateRangePicker, LoadingButton, LocalizationProvider } from '@mui/lab';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import AppBarMenu from '../ui/AppBarMenu';

import DateAdapter from '@mui/lab/AdapterMoment';

import moment from 'moment';

import 'moment/locale/es-mx';

import { BuscarListadoEnvio } from '../../actions/notificaciones/listadoEnvioActions';

export const ConsultarListadoEnvioScreen = () => {

    const [dataTable, setDataTable] = useState( [] );

    const [openNotificaciones, setOpenNotificaciones] = useState(false);

    const [openListadoEnvio, setOpenListadoEnvio] = useState(false);

    const [detalleNotificaciones, setDetalleNotificaciones] = useState( [ ] );

    const [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages();

    const [ pageNotificaciones, rowsPerPageNotificaciones, handleChangePageNotificaciones, handleChangeRowsPerPageNotificaciones, resetPageNotificaciones ] = usePages();

    const [fechas, setFechas] = useState( [ null, null ] );

    const [documento, setDocumento] = useState( '' );
    const [documentoFirmado, setDocumentoFirmado] = useState( '' );

    const [ fechaInicio, fechaFin ] = fechas;

    const [loading, setLoading] = useState( false );
    const [searching, setSearching] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );

    const HandleBuscarListas = async () => {

        setSearching( true );
        resetPage();    
       
    }

    const handleCloseDetalleNotificaciones = () => {
        
        setDetalleNotificaciones( [ ] );
        setOpenNotificaciones( false ); 
        resetPageNotificaciones( 0 );
        
    }

    const handleCloseListaNotificaciones = () => {

        setDocumento( '' );
        setDocumentoFirmado( '' );
        setOpenListadoEnvio( false );

    }

    const HandleClearFiltro = () => {

        setSearching( false );

        setFechas([ null, null ]);

        setDataTable( [ ] );
        setRecordsCount( 0 );

        resetPage();
    }

    useEffect(() => {
      
        async function Obtener(){

            setLoading( true );
            setDataTable( [ ] );      

            await BuscarListadoEnvio( { page, rowsPerPage, fechaInicio : moment( fechaInicio ).format('YYYY-MM-DD'), fechaFin : moment( fechaFin ).format('YYYY-MM-DD') } ).then( response => {
    
                if( response ){
             
                    if( response.data ){ 

                        setTimeout(() => {
                                 
                            setDataTable( response?.data?.records ?? [] );
                            setRecordsCount( response?.data?.recordsCount ?? 0 );
    
                            setLoading( false );          
    
                        }, 1000);                    
    
                    } else {
    
                        setLoading( false );
                        
                    }
    
                }
    
            });

        }

        if( searching ){
            Obtener();
        }
  
    }, [ page, rowsPerPage, fechaInicio, fechaFin, searching ])   

    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '7em', minHeight: '100%', py: 3 }}>  
                        
                <Container maxWidth="lg" sx={{ mt: 1, pb: 2 }} component={ Paper } >
                        
                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12}>

                            <LocalizationProvider dateAdapter={ DateAdapter }>

                                <DateRangePicker
                                    startText="Fecha inicio"
                                    endText="Fecha fin"
                                    value={ fechas }
                                    disabled={ searching }
                                    onChange={ ( value ) => {  setFechas(value); }}
                                    renderInput={ ( startProps, endProps ) => (
                                        <>
                                            <TextField 
                                                {...startProps} 
                                                fullWidth 
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarTodayIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            
                                            <Box sx={{ mx: 2 }}> </Box>
                                            
                                            <TextField 
                                                {...endProps} 
                                                fullWidth 
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarTodayIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </>
                                    )}                                    
                                />

                            </LocalizationProvider>
                         
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>

                            <LoadingButton 
                                variant="contained" 
                                color={ searching ? "inherit" : "primary" } 
                                startIcon={ searching ? <ClearIcon /> : <SearchIcon /> } 
                                onClick={ () => searching ? HandleClearFiltro() : HandleBuscarListas() } 
                                disabled={ !fechaInicio || !fechaFin }
                                loading={ loading }
                            >
                               { searching ? 'Limpiar' : 'Buscar'}
                            </LoadingButton>
                         
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ mt: 2 }} >

                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>

                                            <TableCell style={{ width: '10%', fontWeight: 'bold' }}> NO. SEGUIMIENTO </TableCell>
                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}> FECHA </TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}> GENERÓ LISTADO </TableCell>
                                            <TableCell style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}> NO. NOTIFICACIONES </TableCell>                                            
                                            <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}> NOTIFICACIONES </TableCell>                                                                                        
                                            <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}> LISTADO DE ENVÍO </TableCell>   
                                            <TableCell style={{ width: '15%', textAlign: 'center', fontWeight: 'bold' }}> ESTATUS </TableCell> 

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            dataTable.map( ( row ) => (

                                                <TableRow key={ row.idListadoEnvio } >
                                                    
                                                    <TableCell sx={{ fontWeight: 'bold' }}> GLEN-{ row.idListadoEnvio } </TableCell>  
                                                    <TableCell> { row.fechaGeneracion } </TableCell>                                                            
                                                    <TableCell> { row.usuarioGenero } </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}> 
                                                        { row.numeroNotificaciones } 
                                                    </TableCell>
                                                   
                                                    <TableCell sx={{ textAlign: 'center' }}> 
                                                        
                                                        <IconButton
                                                            onClick={  () => { setOpenNotificaciones(true); setDetalleNotificaciones( row.notificaciones ) } }
                                                            color='primary'
                                                        >
                                                            <FormatListBulletedIcon fontSize='large' /> 
                                                        </IconButton>     

                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}> 

                                                        <IconButton
                                                            onClick={ () => { setOpenListadoEnvio( true ); setDocumento( row.documentoListadoEnvio ); setDocumentoFirmado( row.documentoListadoEnvioFirmada ); }  }
                                                            color='primary'
                                                        >
                                                            <ArticleIcon fontSize='large' /> 
                                                        </IconButton>                                                        

                                                    </TableCell>

                                                    <TableCell sx={{ textAlign: 'center' }}> 
                                                                
                                                        {
                                                            row.estatus
                                                            ?
                                                            <>
                                                                Enviada
                                                                <br/><strong>{ row.fechaEnvio}</strong>
                                                            </>
                                                            :
                                                                'No enviada'
                                                        }

                                                    </TableCell>

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            dataTable.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={7}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                    {
                                                        loading
                                                        ?
                                                            'Buscando ....'
                                                        :
                                                            'No se encontraron resultados'
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>  
                                        
                                        <TableRow>

                                            <TableCell colSpan={2}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                            </TableCell> 
                                      
                                            <TablePagination 
                                                colSpan={7}
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                count={ recordsCount }
                                                rowsPerPage={ rowsPerPage }
                                                page={ page }
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }

                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }                                                                                       
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>
                                
                            </TableContainer>

                        </Grid>                         

                    </Grid>

                </Container>    

                <Dialog open={ openNotificaciones } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
                    <DialogContent>
                        
                        <Grid container spacing={3} >                                                
                        
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        
                            <TableContainer>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>FECHA</TableCell>
                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                            <TableCell style={{ width: '25%', fontWeight: 'bold' }}>PERSONA A NOTIFICAR</TableCell>
                                            <TableCell style={{ width: '30%', fontWeight: 'bold' }}>DOMICILIO</TableCell>
                                            <TableCell style={{ width: '15%', fontWeight: 'bold' }}>TIPO DE NOTIFICACIÓN</TableCell>
                                            <TableCell style={{ width: '20%', fontWeight: 'bold' }}>TIPO DE ACUERDO</TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {
                                            (
                                                rowsPerPageNotificaciones > 0
                                                ?
                                                detalleNotificaciones.slice( pageNotificaciones * rowsPerPageNotificaciones, pageNotificaciones * rowsPerPageNotificaciones + rowsPerPageNotificaciones)
                                                :
                                                detalleNotificaciones
                                            ).map( ( row ) => (

                                                <TableRow key={ row.id } >

                                                        <TableCell> { row.fechaNotificacion ? moment( row.fechaNotificacion ).format('DD-MM-YYYY hh:mm a') : '' }  </TableCell>  
                                                        
                                                        <TableCell> { row.referencia } </TableCell>     

                                                        <TableCell> { row.personaNotificar } <br /> (<strong>{ row.tipoParte }</strong>)  </TableCell> 

                                                        <TableCell> { row.domicilio } </TableCell>   

                                                        <TableCell> { row.tipoNotificacion } </TableCell>         

                                                        <TableCell> { row.tipoAcuerdo } <br /> <strong>{ row.subTipoAcuerdo }</strong>  </TableCell>                                                

                                                </TableRow>

                                            ))
                                        }

                                        {
                                            detalleNotificaciones.length === 0 && 
                                            (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{ fontWeight: 'bold', fontSize: 15 }}
                                                    >
                                                        No se encontraron resultados
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>
                                            <TablePagination 
                                                colSpan={6}
                                                rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                count={ detalleNotificaciones.length }
                                                rowsPerPage={ rowsPerPageNotificaciones}
                                                page={ pageNotificaciones }
                                                onPageChange={ handleChangePageNotificaciones}
                                                onRowsPerPageChange={ handleChangeRowsPerPageNotificaciones }

                                                ActionsComponent={ TablePaginationActions }

                                                labelRowsPerPage="Filas por página"                                            
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }                                                                                       
                                            />
                                        </TableRow>

                                    </TableFooter>

                                </Table>

                                </TableContainer>

                            </Grid>
                            
                        </Grid>
                        
                    </DialogContent>

                    <DialogActions>

                        <Button color="primary" onClick={ handleCloseDetalleNotificaciones }>
                            Cerrar
                        </Button>

                    </DialogActions>                

                </Dialog>   

                <Dialog open={ openListadoEnvio } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
                    <DialogContent>
                        
                        <Grid container spacing={3} >                                                
                        
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        
                                {
                                    documento || documentoFirmado
                                    ?
                                        documentoFirmado
                                        ?
                                        <iframe title='lista' src={ documentoFirmado } height="500px" width="100%"></iframe>
                                        :
                                        <iframe title='lista' src={ "data:application/pdf;base64," + documento } height="500px" width="100%"></iframe>
                                    :
                                    null
                                }

                            </Grid>
                            
                        </Grid>
                        
                    </DialogContent>

                    <DialogActions>

                        <Button color="primary" onClick={ handleCloseListaNotificaciones  }>
                            Cerrar
                        </Button>

                    </DialogActions>                

                </Dialog>          
              
            </Box>
            
        </>
    )
}
